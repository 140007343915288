import { useUserStore } from '@voix/store/userStore'
import type { UserInterface } from '@voix/types'

export function useVoixUserAuth() {
  const checkPermission = (user: UserInterface | null, permission: string): boolean => {
    if (permission === '*')
      return true

    if (!user)
      return false

    const foundPermission = user.all_permissions.find((userPermission) => {
      // console.log(userPermission.name.slice(-1) === '*', userPermission.name.slice(-2) === '.*')
      // If the last character is a period and * then it's a wildcard
      if (userPermission.name.slice(-1) === '*' && userPermission.name.slice(-2) === '.*') {
        const permissionParts = permission.split('.')
        const userPermissionParts = userPermission.name.split('.')
        return userPermissionParts[0] === permissionParts[0]
      }

      return userPermission.name.indexOf(permission) === 0
    })

    if (foundPermission)
      return true

    return false
  }

  const checkUserPermissions = (permissions: string | Array<string>): boolean => {
    const userStore = useUserStore()
    const currentUser = userStore.currentUser

    if (typeof permissions === 'object') {
      for (const permission of permissions) {
        if (checkPermission(currentUser, permission))
          return true
      }

      return false
    }

    return checkPermission(currentUser, permissions)
  }

  return { checkPermission, checkUserPermissions }
}
